export const limits = {
  keyInfo: {
    words: 1000,
    uploads: 15,
    fileSize: 5,
  },
  criterion: {
    overview: {
      words: 1000,
      uploads: 5,
      fileSize: 5,
    },
    pillars: {
      direction: {
        'purpose-strategy': {
          items: [12, 6],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
        'leadership-culture': {
          items: [12, 6],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
        // innovation-bma
        'vision-leadership': {
          items: [12, 6],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
        'innovation-culture': {
          items: [12, 6],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
      },
      execution: {
        'engaging-stakeholders': {
          items: [12, 6],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
        'creating-value': {
          items: [12, 6],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
        'driving-performance': {
          items: [12, 6],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
        // innovation-bma
        'stakeholder-collaboration': {
          items: [12, 6],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
        'process-resources': {
          items: [12, 6],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
      },
      results: {
        'stakeholder-perceptions': {
          items: [15, 15],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
        'strategic-performance': {
          items: [40, 40],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
        // innovation-bma
        'business-and-market': {
          items: [15, 15],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
        'stakeholder-impact': {
          items: [40, 40],
          words: 100,
          uploads: 3,
          fileSize: 5,
        },
      },
    },
  },
}
