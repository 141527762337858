import React, { useCallback } from 'react'
import { Grid, Box, withStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import FileItem from './FileItem'
import { SectionTitle } from 'gatsby-components'

const AssessmentFiles = ({
  classes,
  assessment,
  files,
  theme,
  pillarColors,
}) => {
  if (!assessment) return null
  const { t } = useTranslation()

  const getFilesList = useCallback(() => {
    const filesList = []
    const topFiles = files.filter(
      file => file.pillar_key === null && file.criterion_key === null
    )

    filesList.push(...topFiles)

    let criteriaNo = 0

    assessment.pillars.map((pillar, pillarIndex) =>
      pillar.criteria.map(criterion => {
        criteriaNo += 1
        const criterionFiles = files.filter(
          file =>
            file.pillar_key === pillar.key &&
            file.criterion_key === criterion.key &&
            file.part_number === null
        )

        const finalCriterionFiles = criterionFiles.map(file => ({
          ...file,
          attachmentIconColor: pillarColors[pillarIndex],
          prefix: criteriaNo,
        }))

        filesList.push(...finalCriterionFiles)

        criterion.parts.map(({ tables: [{ name }] }, index) => {
          const partFiles = files.filter(
            file =>
              file.pillar_key === pillar.key &&
              file.criterion_key === criterion.key &&
              file.part_number === index + 1
          )

          const finalPartFiles =
            (partFiles.length > 0 &&
              partFiles.map(file => ({
                ...file,
                attachmentIconColor: pillarColors[pillarIndex],
                prefix: `${criteriaNo}.${file.part_number}`,
              }))) ||
            []

          filesList.push(...finalPartFiles)
        })
      })
    )

    return filesList
  }, [files, assessment, pillarColors])

  const filesList = getFilesList()
  return (
    <div className={classes.section}>
      <Grid container spacing={3}>
        <Grid item>
          <SectionTitle barColor={theme.palette.primary.main}>
            {t('Assessment documents')}
          </SectionTitle>
        </Grid>
      </Grid>
      <Grid>
        <Box className={classes.list}>
          {filesList.map(file => (
            <Box className={classes.listItem} key={file.s3_key}>
              <FileItem
                file={file}
                prefix={file.prefix ? file.prefix : t('KI')}
                attachmentIconColor={
                  file.attachmentIconColor
                    ? file.attachmentIconColor
                    : theme.palette.primary.dark
                }
              />
            </Box>
          ))}
        </Box>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  section: {
    margin: theme.spacing(3, 0),
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
  },
  listItem: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    width: '25%',
    alignItems: 'center',
  },
})

export default withStyles(styles, { withTheme: true })(AssessmentFiles)
