import React from 'react'
import T from 'prop-types'
import classnames from 'classnames'
import { Button, withStyles } from '@material-ui/core'

const UploadButton = ({
  onFileSelected,
  classes,
  id,
  grow,
  imagesOnly,
  fileSize,
  ...buttonProps
}) => {
  const handleChange = event => {
    const file = event.target.files[0]
    if (!!fileSize && file.size > fileSize * 1e6) {
      alert('File is too big!')
      return
    }

    onFileSelected(file)
  }

  return (
    <>
      {!buttonProps.disabled && (
        <input
          accept={imagesOnly ? 'image/x-png,image/gif,image/jpeg' : '*/*'}
          id={id}
          type="file"
          className={classes.hiddenInput}
          onChange={handleChange}
        />
      )}
      <label htmlFor={id} className={classnames({ [classes.grow]: grow })}>
        <Button component="span" {...buttonProps} />
      </label>
    </>
  )
}

UploadButton.defaultProps = {
  id: 'outlined-button-file',
  grow: false,
  imagesOnly: false,
}

UploadButton.propTypes = {
  onFileSelected: T.func.isRequired,
  id: T.string,
  grow: T.bool,
  imagesOnly: T.bool,
  fileSize: T.number,
}

const styles = {
  hiddenInput: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  },
}

export default withStyles(styles)(UploadButton)
