import React, { useState } from 'react'
import { Button, makeStyles, Typography } from '@material-ui/core'
import AttachmentIcon from '@material-ui/icons/Attachment'
import DeleteIcon from '@material-ui/icons/Delete'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'
import isNumber from 'lodash/isNumber'
import { useMutation } from 'graphql-hooks'

import { ConfirmDialog } from 'gatsby-components'
import {
  downloadFile,
  deleteFile,
  downloadGuidanceFile,
} from '../utils/storage'
import { fileType } from '../prop-types'
import { deleteFileUploadMutation } from '../queries'

// adapted from https://gist.github.com/lanqy/5193417
function formatFileSize(bytes) {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return `0 ${sizes[0]}`
  const k = 1024
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)), 10)
  if (i == 0) return bytes + ' ' + sizes[i]
  return (bytes / Math.pow(k, i)).toFixed(1) + ' ' + sizes[i]
}

function FileItem({
  file,
  canDelete,
  onDeleteComplete,
  isGuidanceFile,
  prefix,
  attachmentIconColor = null,
}) {
  const classes = useStyles({ attachmentIconColor })
  const [show, setShow] = useState(false)
  const { t } = useTranslation()
  const [deleteFileUpload] = useMutation(deleteFileUploadMutation)

  const doDeleteFile = async key => {
    await deleteFile(key)
    await deleteFileUpload({ variables: { s3Key: key } })
    onDeleteComplete()
  }

  return (
    <Button
      onClick={_ =>
        isGuidanceFile ? downloadGuidanceFile(file) : downloadFile(file)
      }
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {prefix && (
        <Typography className={classes.prefix}>{prefix}&nbsp;</Typography>
      )}
      <AttachmentIcon className={classes.attachmentIcon} />
      <div className={classes.attachment}>
        <Typography variant="h4" className={classes.fileName}>
          {file.file_name}
        </Typography>
        {isNumber(file.file_size) && (
          <Typography variant="h4" color="secondary">
            {formatFileSize(file.file_size)}
          </Typography>
        )}
      </div>
      {canDelete && !isGuidanceFile && (
        <div
          onClick={e => e.stopPropagation()}
          className={show ? classes.visible : classes.hidden}
        >
          <ConfirmDialog
            title={t(`Delete File`, { fileName: file.file_name })}
            text={t(
              `This file will be permanently deleted. This cannot be undone.`
            )}
            onConfirm={_ => doDeleteFile(file.s3_key)}
            onCancel={_ => setShow(false)}
            okayLabel={t('Delete')}
          >
            <DeleteIcon className={classes.actionButton} />
          </ConfirmDialog>
        </div>
      )}
    </Button>
  )
}

FileItem.propTypes = {
  classes: T.object,
  file: fileType.isRequired,
  canDelete: T.bool,
  onDeleteComplete: T.func,
  isGuidanceFile: T.bool,
  attachmentIconColor: T.string,
}

const useStyles = makeStyles(theme => ({
  attachmentIcon: {
    marginRight: theme.spacing(2),
    color: ({ attachmentIconColor }) =>
      attachmentIconColor ? attachmentIconColor : theme.palette.secondary.light,
  },
  attachment: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  fileName: {
    textAlign: 'start',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  actionButton: {
    marginLeft: theme.spacing(2),
    color: '#9cafc3',
  },
  hidden: {
    opacity: '0',
    transition: 'opacity 250ms ease-out',
    '-moz-transition': 'opacity 250ms ease-out',
    '-webkit-transition': 'opacity 250ms ease-out',
    '-o-transition': 'opacity 250ms ease-out',
  },
  visible: {
    opacity: '1',
    transition: 'opacity 250ms ease-in',
    '-moz-transition': 'opacity 250ms ease-in',
    '-webkit-transition': 'opacity 250ms ease-in',
    '-o-transition': 'opacity 250ms ease-in',
  },
  prefix: {
    minWidth: 25,
    textAlign: 'left',
  },
}))

export default FileItem
export { formatFileSize }
